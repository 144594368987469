import { useNavigate } from '@remix-run/react';
import classNames from 'classnames';
import React, { useRef } from 'react';

import type { MouseEvent, KeyboardEvent } from 'react';

type CardLinkProps = {
  as?: React.ElementType;
  children: React.ReactNode;
  className?: string;
  onClick?: (e: MouseEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  to: string;
  'data-testid': string;
  [key: string]: unknown;
};

/* 
  - Component is for using a div or other element as a Link and have nested actions within it
  - To maintain default keyboard navigation, this should not be wrapped in a div when mapped
*/

const CardLink = ({ as = 'div', children, className, onClick, onKeyDown, to, ...rest }: CardLinkProps) => {
  const navigate = useNavigate();
  const cardRef = useRef<HTMLElement>(null);

  const Component = as;

  // Allow for custom onClick, if default not prevented then navigate after
  const handleClick = (e: MouseEvent) => {
    if (
      (e.target as HTMLElement).closest('a, button, input') ||
      // @ts-ignore
      (e.target?.className && e.target.className?.includes('checkbox'))
    )
      return;
    if (onClick) onClick(e);
    if (!e.defaultPrevented) navigate(to);
  };

  // Allow for custom onKeyDown, if default not prevented then use default keyboard navigations
  const handleKeyDown = (e: KeyboardEvent) => {
    if (onKeyDown) onKeyDown(e);
    if (!e.defaultPrevented) {
      const next = cardRef?.current?.nextSibling as HTMLElement;
      const previous = cardRef?.current?.previousSibling as HTMLElement;

      switch (e.key) {
        case 'PageDown': {
          e.preventDefault();
          if (next) next.focus();
          return;
        }
        case 'PageUp': {
          e.preventDefault();
          if (previous) previous.focus();
          return;
        }
        case 'Enter': {
          const isCardTarget = cardRef.current?.isSameNode(e.target as Node);
          if (!isCardTarget) return;
          navigate(to);
          break;
        }
      }
    }
  };

  return (
    <Component
      className={classNames('pointer clickable', className)}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      ref={cardRef}
      tabIndex={0}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default CardLink;
